.section-centered-heading {
    width: 100%;
    margin: 10px 0 20px;
    text-align: center;
    font-weight: 700;
}

.homepage-slider .icon {
    padding: 10px;
    font-size: 1.75rem;
    line-height: 1rem;
    color: #005F74;
}

body[dir="rtl"] .nnext-arrow {
    position: relative;
    top: 7px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

body[dir="rtl"] .h-jc-link {
    direction: rtl;
}

.homepage-jobs {
    display: flex;
    flex-direction: column;
}

.homepage-jobs > div:nth-child(2) {
    flex: 1 1 auto;
}

.homepage-jobs > div:nth-child(3) {
    padding-top: 15px;
}