header.MuiAppBar-colorPrimary {
    position: relative;
    z-index: 2;
    width: 100%;
    box-shadow: 0 3px 8px rgba(0,0,0,0.15);
    background-color: #fff;
    color: #000;
}

header.MuiAppBar-colorPrimary h6 {
    font-size: 1.15rem;
    font-weight: 400;
    color: #000;
}

header.MuiAppBar-colorPrimary a {
    color: #000;
}

.MuiDrawer-paper {
    min-width: 180px;
}

.logo {
    display: inline-flex;
    max-width: 153px;
}

.logo img {
    max-width: 100%;
    height: auto;
}

.title {
    display: flex;
    flex-basis: 255px;
    align-items: center;
}



body[dir="ltr"] .menu-toggle {
    padding: 0;
    margin-right: 15px;
}

body[dir="rtl"] .menu-toggle {
    padding: 0;
    margin-left: 15px;
}

@media screen and (max-width: 800px) {
    header > div {
        display: flex;
        flex-direction: column;
    }
    .logo {
        margin-top: 5px;
    }
    .title {
        flex-basis: 40px;
        order: 1;
    }
    .language-switcher {
        order: 2;
        margin: 0 auto !important;
    }
}
