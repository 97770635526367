body[dir="ltr"] .MuiOutlinedInput-adornedEnd {
  padding-left: 0;
}

body[dir="ltr"] .MuiInputAdornment-positionEnd {
  margin-right: 0;
}

body[dir="rtl"] .MuiOutlinedInput-adornedEnd {
  padding-right: 0;
}

body[dir="rtl"] .MuiInputAdornment-positionEnd {
  margin-left: 0;
}

.custom-select  > label {
  pointer-events: none;
}