div.job-details-icon {
	padding: 10px;
	font-size: 1.3rem;
	line-height: 1.3rem;
	text-align: center;
}

.description-ul {
	padding: 0;
	padding-left: 20px;
}

.description-ul li {
	margin-bottom: 8px;
	font-size: 0.9rem;
	line-height: 1.2;
}

body[dir="rtl"] .description-ul {
	padding-left: 0;
	padding-right: 20px;
}

.mb-35 {
	margin-bottom: 25px !important;
}
 
.job-card-slider > div:nth-child(1) {
	min-height: 60px;
	font-size: 1.1rem;
	line-height: 1.25rem;
}

.job-card-slider > div:nth-child(2) {
	font-size: 0.75rem;
	line-height: 1rem;
	padding-top: 15px;
	min-height: 90px;
}

body[dir="rtl"] .job-card-slider > div:nth-child(3) svg {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}