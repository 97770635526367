body .language-switcher {
  color: #000;
}

body .language-switcher button {
  color: #000;
}

body[dir="ltr"] .language-switcher {
  margin-left: auto;
  margin-right: 5px;
}

body[dir="rtl"] .language-switcher {
  margin-right: auto;
  margin-left: 5px;
}

#enSelected * {
  font-family: 'Cairo';
  font-weight: 400;
}

#arSelected * {
  font-family: 'Roboto';
  font-weight: 400;
}
