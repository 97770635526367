.MuiToolbar-root.MuiToolbar-regular.MuiPickersToolbar-toolbar.MuiPickersDatePickerRoot-toolbar {
    height: 50px;
}

.MuiToolbar-root.MuiToolbar-regular.MuiPickersToolbar-toolbar.MuiPickersDatePickerRoot-toolbar.MuiToolbar-gutters > button:nth-child(2) {
    display: none;
}

.MuiStep-root.MuiStep-vertical:hover {
    cursor: pointer;
}