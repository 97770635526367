.mb-15 {
  margin-bottom: 15px;
}

body.loading {
  overflow: hidden;
}

.MuiButton-outlined.Mui-disabled {
  border-color: #ddd !important;
}

body[dir="ltr"] .login-form-wrapper {
  background: url('/src/assets/images/login-bg.png');
  background-repeat: repeat-y;
}

body[dir="ltr"] .MuiStepConnector-vertical {
  margin-left: 12px;
  margin-right: 0;
}
body[dir="ltr"] .MuiStepConnector-lineVertical {
  border-right-width: 0;
  border-left-width: 1px;
}

body[dir="ltr"] .MuiStepLabel-iconContainer {
  padding: 0 8px 0 0;
}

.slick-slider.slick-initialized {
  height: 280px;
  margin: 20px 0;
}
.slick-prev:before,
.slick-next:before {
  display: none;
}

.slick-slider .slick-list {
  min-height: 280px;
}

body[dir="ltr"] .slick-next,
body[dir="ltr"] .slick-prev {
  width: 44px;
  height: 67px;
  transform: translateY(-50%);
}

body[dir="ltr"] .slick-initialized .slick-next {
  right: 0;
  left: auto;
  text-align: right;
}

body[dir="ltr"] .slick-initialized .slick-prev {
  left: 0;
  right: auto;
  text-align: left;
}


/************************/
/**** arabic styling ****/
/************************/
body[dir="rtl"] {
  font-family: 'Cairo', sans-serif;
  font-weight: 400;
}

body[dir="rtl"] .custom-icon {
  left: 16px;
  right: auto;
}

body[dir="rtl"] .search-icon {
  left: 16px;
  right: auto;
}

body[dir="rtl"] * {
  font-family: inherit;
  font-weight: 400;
}

body[dir="rtl"] h1, body[dir="rtl"] h2, body[dir="rtl"] h3, body[dir="rtl"] h4,
body[dir="rtl"] h5, body[dir="rtl"] h6 {
  font-family: inherit;
  font-weight: 700;
}

body[dir="rtl"] .login-form-wrapper {
  background: url('/src/assets/images/login-ar-bg.png');
}

body[dir="rtl"] .MuiStepConnector-vertical {
  margin-right: 12px;
  margin-left: 0;
}
body[dir="rtl"] .MuiStepConnector-lineVertical {
  border-left-width: 0;
  border-right-width: 1px;
}
body[dir="rtl"] .MuiStepLabel-iconContainer {
  padding: 0 0 0 8px;
}

body[dir="rtl"] .slick-initialized {
  overflow: hidden;
}

body[dir="rtl"] .slick-initialized .slick-next,
body[dir="rtl"] .slick-initialized .slick-prev {
  width: 44px;
  transform: rotate(180deg) translateY(50%);
}

body[dir="rtl"] .slick-initialized .slick-next {
  left: 0;
  right: auto;
  text-align: left;
}

body[dir="rtl"] .slick-initialized .slick-prev {
  right: 0;
  left: auto;
  text-align: right;
}


/***** forms *****/

span.MuiCheckbox-colorSecondary.Mui-checked {
  color: #005F74;
}

.MuiFormHelperText-root.Mui-error {
  display: block;
  position: relative;
  margin: 2px 0 2px 0;
  line-height: 1;
  font-size: 0.7rem;

}

/**** general information *****/
.general-message {
  margin-top: 20px;
  padding: 10px;
  -webkit-box-shadow: 5px;
  -moz-box-shadow: 5px;
  -o-box-shadow: 5px;
  box-shadow: 5px;
  background-color: #e6e8eb;
  text-align: left;
  font-size: 0.8rem;  
}

.general-message .inline-ele {
  margin-right: 12px;
}

.general-message a {
  color: #005F74;
}

.MuiFormHelperText-root.MuiFormHelperText-root {
  color: tomato ;
  margin: 0px;
  font-size: 0.6rem;
  width: max-content;
}

.MuiAlert-root {
  max-width: 450px;
}

.MuiInputBase-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.9) !important;
}

body[dir="rtl"] .general-message {
  text-align: right;
}

body[dir="rtl"] .general-message .inline-ele {
  margin-right: 0;
  margin-left: 12px;
}

/* .MuiPopover-paper .MuiPickersToolbar-toolbar {
  display: none;
} */

.MuiPopover-paper .MuiPickersToolbar-toolbar {
  background-color: #005F74;
}

.MuiPopover-paper .MuiTypography-colorPrimary,
.MuiPopover-paper .MuiPickersYear-root:focus,
.MuiPopover-paper .MuiPickersMonth-root:focus {
  color: #005F74;
}

.MuiPopover-paper .MuiPickersDay-current {
  color: #005F74;
}

.MuiPopover-paper .MuiPickersDay-daySelected,
.MuiPopover-paper .MuiPickersDay-daySelected:hover {
  background-color: #005F74;
  color: #ffff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@-webkit-keyframes autofill {
  0%,100% {
      color: #666;
      background: transparent;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px white inset !important;
}


@media screen and (min-width: 700px) {
  .slider-footer {
      display: none;
  }
}