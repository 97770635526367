.success-text {
    margin-bottom: 10px;
    font-size: 0.75rem;
    color: #28a745;
    text-align: center;
}

.success-msg, .error-msg {
    padding: 25px;
    text-align: center;
    font-size: 1rem;
    color: #28a745;
}

.success-msg, .error-msg .icon svg {
    width: 1.75rem;
}

.success-msg, .error-msg .icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50px;
    height: 50px;
    margin: 0 auto 15px;
    padding: 11px 0;
    border: 2px solid #28a745;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
}

.error-msg {
    color: tomato;
}
.error-msg .icon {
    border: 2px solid tomato;
}
.login-slider-section .slick-slider {
    max-width: 80%;
}

.link {
    text-decoration: none;
    cursor: pointer;
}