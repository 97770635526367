.stepper-icon .MuiStepLabel-iconContainer .MuiSvgIcon-root {
    font-size: 0.9rem;
}

.stepper-icon .MuiStepLabel-iconContainer > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.stepper-icon .MuiStepLabel-labelContainer > span {
    font-size: 0.95rem;
    font-weight: 500;
}

.stepper-content-div {
    padding-top: 17px;
}
