@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?kqu2gj');
    src:  url('../fonts/icomoon.eot?kqu2gj#iefix') format('embedded-opentype'),
      url('../fonts/icomoon.ttf?kqu2gj') format('truetype'),
      url('../fonts/icomoon.woff?kqu2gj') format('woff'),
      url('../fonts/icomoon.svg?kqu2gj#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-hiring-entity:before {
    content: "\e916";
  }
  .icon-job-location:before {
    content: "\e917";
  }
  .icon-number-of-candidates:before {
    content: "\e918";
  }
  .icon-work-nature:before {
    content: "\e919";
  }
  .icon-attachment:before {
    content: "\e911";
  }
  .icon-calendar:before {
    content: "\e912";
  }
  .icon-jobdetails:before {
    content: "\e913";
  }
  .icon-personalinfo:before {
    content: "\e914";
  }
  .icon-scientificdetail:before {
    content: "\e915";
  }
  .icon-accounting-icon:before {
    content: "\e900";
  }
  .icon-arrow-icon:before {
    content: "\e901";
  }
  .icon-career-level-icon:before {
    content: "\e902";
  }
  .icon-date-posted-icon:before {
    content: "\e903";
  }
  .icon-experience-icon:before {
    content: "\e904";
  }
  .icon-expiration-date:before {
    content: "\e905";
  }
  .icon-eye-icon:before {
    content: "\e906";
  }
  .icon-location-icon:before {
    content: "\e907";
  }
  .icon-lock-icon:before {
    content: "\e908";
  }
  .icon-management-icon:before {
    content: "\e909";
  }
  .icon-qualification-icon:before {
    content: "\e90a";
  }
  .icon-read-more-arrow-icon:before {
    content: "\e90b";
  }
  .icon-sales-n-markeing-icon:before {
    content: "\e90c";
  }
  .icon-slider-next-icon:before {
    content: "\e90d";
  }
  .icon-slider-prev-icon:before {
    content: "\e90e";
  }
  .icon-user-icon:before {
    content: "\e90f";
  }
  .icon-web-n-software-dev-icon:before {
    content: "\e910";
  }
  

  /** font embed css **/

  @font-face {
    font-family: 'Cairo';
    src: url('../fonts/Cairo-Regular.eot');
    src: url('../fonts/Cairo-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Cairo-Regular.woff2') format('woff2'),
        url('../fonts/Cairo-Regular.woff') format('woff'),
        url('../fonts/Cairo-Regular.ttf') format('truetype'),
        url('../fonts/Cairo-Regular.svg#Cairo-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cairo';
    src: url('../fonts/Cairo-Bold.eot');
    src: url('../fonts/Cairo-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Cairo-Bold.woff2') format('woff2'),
        url('../fonts/Cairo-Bold.woff') format('woff'),
        url('../fonts/Cairo-Bold.ttf') format('truetype'),
        url('../fonts/Cairo-Bold.svg#Cairo-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}


@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.eot');
  src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto-Regular.woff2') format('woff2'),
      url('../fonts/Roboto-Regular.woff') format('woff'),
      url('../fonts/Roboto-Regular.ttf') format('truetype'),
      url('../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Bold.eot');
  src: url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto-Bold.woff2') format('woff2'),
      url('../fonts/Roboto-Bold.woff') format('woff'),
      url('../fonts/Roboto-Bold.ttf') format('truetype'),
      url('../fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium.eot');
  src: url('../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto-Medium.woff2') format('woff2'),
      url('../fonts/Roboto-Medium.woff') format('woff'),
      url('../fonts/Roboto-Medium.ttf') format('truetype'),
      url('../fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


