.login-icon {
    position: relative;
    top: 6px;
    width: 1rem; 
}

body[dir="rtl"] .login-icon {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
