body[dir="ltr"] {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body[dir="ltr"] h1, body[dir="ltr"] h2, body[dir="ltr"] h3, body[dir="ltr"] h4, 
body[dir="ltr"] h5, body[dir="ltr"] h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

body[dir="ltr"] input, body[dir="ltr"] select,
body[dir="ltr"] button {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
} 

body[dir="rtl"] {
  margin: 0;
  font-family: 'Cairo', sans-serif;
  font-size: 16px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body[dir="rtl"] h1, body[dir="rtl"] h2, body[dir="rtl"] h3, body[dir="rtl"] h4, body[dir="rtl"] h5, body[dir="rtl"] h6 {
  font-family: 'Cairo', sans-serif;
  font-weight: 800;
}

body[dir="rtl"] .slick-slide {
  text-align: right;
}

body[dir="ltr"] .react-tel-input .selected-flag .flag {
  position: absolute;
  top: 50%;
  margin-top: -5px;
}
body[dir="rtl"] .react-tel-input .selected-flag .flag {
  position: absolute;
  top: 50%;
  margin-top: -5px;
  transform: translate(-50%, -50%);
  transform: rotate(180deg);
}
body[dir="rtl"] .react-tel-input .country-list {
  direction: ltr;
}
body[dir="rtl"] .react-tel-input .flag-dropdown {
  left: 0;
}

body[dir="rtl"] .react-tel-input .selected-flag {
  padding: 0 12px 0 0;
}
body[dir="rtl"] .react-tel-input .selected-flag .arrow {
  right: 20px;
  left: 0;
}
body[dir="ltr"] .MuiAlert-root {
  padding: 6px 28px !important;
}
body[dir="ltr"] .MuiAlert-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 22px !important;
}
body[dir="rtl"] .MuiAlert-root {
  padding: 6px 17px !important;
}
body[dir="rtl"] .MuiAlert-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 22px !important;
}
