@keyframes loader {
    0% {
        -webkit-transform: scale(0, 0);
        transform: scale(0, 0);
        opacity: 0.8;
   }
    100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        opacity: 0;
   }
}
@-webkit-keyframes loader {
    0% {
        -webkit-transform: scale(0, 0);
        opacity: 0.8;
   }
    100% {
        -webkit-transform: scale(1, 1);
        opacity: 0;
   }
}
.loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 100000;
}
.loader-loader {
    width: 50px;
    height: 50px;
    display: inline-block;
    padding: 0;
    text-align: left;
    position: relative;
}
.loader-loader span {
    position: absolute;
    left: 0;
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: rgba(0, 0, 0, .8);
    -webkit-animation: loader 1.5s linear infinite;
    animation: loader 1.5s linear infinite;
}
.loader-loader span:last-child {
    animation-delay: -0.9s;
    -webkit-animation-delay: -0.9s;
}
.loader-fixed {
    position: fixed;
}
