/** the css update **/
div#staticMenu {
  margin-left: 5px;
  padding-left: 5px;
  border-left: 2px solid #cdcdcd;
}

html[lang="ar"] div#staticMenu {
  margin-left: 0;
  padding-left: 0;
  border-left: none;
  margin-right: 5px;
  padding-right: 5px;
  border-right: 2px solid #cdcdcd;
}

div#staticMenu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

div#staticMenu ul li {
  display: inline-block;
  margin: 0 5px;
}

div#staticMenu ul li a {
  text-decoration: none;
}

div#staticMenu ul li a:hover {
  color: #5a93c6;
}

/** current menu change update **/
.title {
  flex-basis: 190px;
}

html[lang="ar"] .title {
  flex-basis: 115px;
}

@media screen and (max-width: 800px) {
  div#staticMenu {
    order: 2;
    margin-bottom: 5px;
    padding: 0;
    border: none;
  }

  .title {
    flex-basis: auto;
  }
}
